import { useAuth } from '@guider-global/auth-hooks';
import {
  getCountryCodeName,
  getCountryList,
} from '@guider-global/front-end-utils';
import {
  getTimezoneAbbreviation,
  getUniqueElements,
} from '@guider-global/isomorphic-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
  useSettings,
} from '@guider-global/sanity-hooks';
import {
  IChartsOverviewGuideProfile,
  IChartsUser,
  IFilterModel,
} from '@guider-global/shared-types';
import {
  Button,
  Chip,
  IKeyStatCard,
  IMultiSelectChipFilterOption,
  ITableRefs,
  KeyStatCard,
  PieChartCard,
  Stack,
  Text,
  getCustomGridAutocompleteFilterOperator,
  getCustomGridDateFilterOperator,
} from '@guider-global/ui';
import Refresh from '@mui/icons-material/Refresh';
import { Grid as MuiGrid, useMediaQuery, useTheme } from '@mui/material';
import { DefaultizedPieValueType } from '@mui/x-charts';
import { GridColDef } from '@mui/x-data-grid';
import {
  MetricsOverviewFilterContainer,
  StackedBarChartsContainer,
  StackedChartContainer,
} from 'containers';
import { MongoMetricsContainer } from 'containers/MongoMetricsContrainer';
import { useAppContext, useOverviewTableContext } from 'context';
import { format, formatDuration } from 'date-fns';
import {
  getCountryColumn,
  getGenderLabel,
  getGoalsColumns,
  getOrganizationColumn,
  getSanityProfileFieldColumns,
} from 'functions';
import {
  useAdminChartsGoals,
  useAdminChartsGoalsCumulative,
  useAdminChartsGuidesByCountry,
  useAdminChartsOverviewGuideProfiles,
  useAdminChartsOverviewTraineeProfiles,
  useAdminChartsTraineesByCountry,
  useAdminChartsUsers,
  useAdminChartsUsersByCountry,
  useAdminChartsUsersByCustomFields,
  useAdminKPIsOrganization,
  useAdminOvertimeCharts,
  useAdminPrograms,
  useSnackbar,
} from 'hooks';
import { useAdminChartsSkillsCumulative } from 'hooks/admin/useAdminChartsSkillsCumulative';
import { useOrganizations } from 'hooks/useOrganizations';
import { registerLocale as countriesRegisterLocale } from 'i18n-iso-countries';
import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageView } from 'views';

const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 75, 100];
const DEFAULT_TO_DATE = new Date();
const DEFAULT_FROM_DATE = new Date();
DEFAULT_FROM_DATE.setDate(DEFAULT_TO_DATE.getDate() - 30);

export function MongoMetricsPage() {
  const { organizationSlug, isRootDomain } = useAppContext();
  const { breakpoints, palette } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  const { organizations } = useOrganizations({
    getSilently: true,
    getSilentlyUrl: isRootDomain ? '/superadmin/organizations' : undefined,
  });

  const { adminPrograms } = useAdminPrograms({
    params: {
      organizationSlug: organizationSlug,
      localeCode: 'en_GB',
    },
  });

  const organizationsDetails = organizations();

  const { setError } = useSnackbar();

  function getOthers(
    data: { value: number; [key: string]: string | number }[],
  ) {
    const topItems = data.sort((a, b) => b.value - a.value).slice(0, 10);
    const others = {
      label: 'Other',
      value: data.reduce(
        (sum: number, item) => sum + (topItems.includes(item) ? 0 : item.value),
        0,
      ),
    };
    return [...topItems, others];
  }

  const {
    isLoading: isLoadingAuth,
    isAuthenticated,
    isAuthorized,
  } = useAuth({});

  // Table State
  const {
    models: {
      userModel,
      guideMembershipsModel,
      traineeMembershipsModel,
      goalsModel,
      kpiModel,
      overtimeModel,
      goalsCumulativeModel,
      skillsCumulativeModel,
    },
    updateGoalsModel,
    updateGuideMembershipsModel,
    updateTraineeMembershipsModel,
    updateUserModel,
  } = useOverviewTableContext();

  const tableRefs: ITableRefs = {
    usersTable: useRef<HTMLDivElement>(null),
    guideTable: useRef<HTMLDivElement>(null),
    traineeTable: useRef<HTMLDivElement>(null),
    goalsTable: useRef<HTMLDivElement>(null),
  };

  const {
    organization: sanityOrganization,
    isLoadingOrganization,
    isValidatingOrganization,
  } = useOrganization({
    organizationSlug: organizationSlug ?? 'guider',
    showHiddenProfileData: true,
  });

  const { settings, isLoadingSettings } = useSettings({});
  const { programs } = useOrganizationPrograms({
    organizationSlug: organizationSlug ?? '',
    localeCode: 'en_GB',
  });

  const { baseLanguage, isLoadingBaseLanguage } = useBaseLanguage({
    localeCode: 'en_GB',
  });

  const concludeRelationshipKpiLabel =
    baseLanguage?.globals?.relationships?.conclude_relationship_kpi_label ?? '';

  const traineeOrganizationLabelPluralized = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.trainee
        ?.pluralized,
  );
  const traineeOrganizationLabel = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.trainee
        ?.singular,
  );

  const guideOrganizationLabel = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.guide
        ?.singular,
  );

  const guideOrganizationLabelPluralized = programs.map(
    (program) =>
      program?.program_details?.program_type?.program_type_text?.common?.guide
        ?.pluralized,
  );

  const traineeLabel = getUniqueElements(traineeOrganizationLabel, 'Trainee');
  const traineeLabelPluralized = getUniqueElements(
    traineeOrganizationLabelPluralized,
    'Trainees',
  );
  const guideLabel = getUniqueElements(guideOrganizationLabel, 'Guide');

  const guideLabelPluralized = getUniqueElements(
    guideOrganizationLabelPluralized,
    'Guides',
  );

  const {
    adminChartsGoals = [],
    isLoadingAdminChartsGoals,
    isValidatingAdminChartsGoals,
    revalidateAdminChartsGoals,
  } = useAdminChartsGoals({
    params: {
      page: goalsModel.paginationModel.page + 1,
      sortModel: goalsModel.sortModel,
      pageLimit: goalsModel.paginationModel.pageSize,
      filterModel: goalsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Goals Chart',
        });
      },
    },
  });

  const {
    adminChartsUsers = [],
    isLoadingAdminChartsUsers,
    isValidatingAdminChartsUsers,
    revalidateAdminChartsUsers,
  } = useAdminChartsUsers({
    params: {
      page: userModel.paginationModel.page + 1,
      pageLimit: userModel.paginationModel.pageSize,
      sortModel: userModel.sortModel,
      filterModel: userModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get All Users Chart',
        });
      },
    },
  });

  const {
    revalidateAdminOvertimeCharts,
    isLoadingAdminOvertimeCharts,
    isValidatingAdminOvertimeCharts,
  } = useAdminOvertimeCharts({
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Overtime Graph',
        });
      },
    },
  });

  const {
    adminChartsOverviewTraineeProfiles = [],
    isLoadingAdminChartsOverviewTraineeProfiles,
    isValidatingAdminChartsOverviewTraineeProfiles,
    revalidateAdminChartsOverviewTraineeProfiles,
  } = useAdminChartsOverviewTraineeProfiles({
    params: {
      page: traineeMembershipsModel.paginationModel.page + 1,
      pageLimit: traineeMembershipsModel.paginationModel.pageSize,
      sortModel: traineeMembershipsModel.sortModel,
      filterModel: traineeMembershipsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: `Failed to get ${traineeLabel} Chart`,
        });
      },
    },
  });

  const {
    adminChartsOverviewGuideProfiles = [],
    isLoadingAdminChartsOverviewGuideProfiles,
    isValidatingAdminChartsOverviewGuideProfiles,
    revalidateAdminChartsOverviewGuideProfiles,
  } = useAdminChartsOverviewGuideProfiles({
    params: {
      page: guideMembershipsModel.paginationModel.page + 1,
      pageLimit: guideMembershipsModel.paginationModel.pageSize,
      sortModel: guideMembershipsModel.sortModel,
      filterModel: guideMembershipsModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: `Failed to get ${guideLabel} Profiles Chart`,
        });
      },
    },
  });

  const {
    adminChartsUsersByCustomFields = [],
    isLoadingAdminChartsUsersByCustomFields,
  } = useAdminChartsUsersByCustomFields({
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Users by CustomFields Chart',
        });
      },
    },
  });

  const parsedAdminChartsUsersByCustomFields = getOthers(
    adminChartsUsersByCustomFields.map((item) => ({
      label: item.id,
      value: item.count,
    })),
  );

  const { adminChartsUsersByCountry = [], isLoadingAdminChartsUsersByCountry } =
    useAdminChartsUsersByCountry({
      params: {
        filterModel: userModel.filterModel,
      },
      options: {
        onError: (error) => {
          setError({
            message: error.message,
            title: 'Failed to get Users by Country Chart',
          });
        },
      },
    });

  const parsedAdminChartsUsersByCountry = getOthers(
    adminChartsUsersByCountry
      .map((item) => ({
        label: getCountryCodeName(item.id) || item.id,
        value: item.count,
      }))
      .filter((item) => Boolean(item.label)),
  );

  const countryOptions: IMultiSelectChipFilterOption[] = useMemo(
    () =>
      getCountryList({ countryCode: 'GB', languageCode: 'en' }).map(
        ({ key, countryName }) => ({
          id: key,
          label: countryName,
          color: 'gray',
          variant: 'outlined',
        }),
      ),
    [],
  );

  // const filteredCountries = countryOptions.filter((item) =>
  //   adminChartsUsersByCountry.map((item) => item.id).includes(item.id),
  // );

  const filteredCountries = useMemo(
    () =>
      countryOptions.filter((item) =>
        adminChartsUsersByCountry.map((item) => item.id).includes(item.id),
      ),
    [adminChartsUsersByCountry, countryOptions],
  );

  const {
    adminChartsGuidesByCountry = [],
    isLoadingAdminChartsGuidesByCountry,
  } = useAdminChartsGuidesByCountry({
    params: {
      filterModel: userModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Guides by Country Chart',
        });
      },
    },
  });

  const parsedAdminChartsGuidesByCountry = getOthers(
    adminChartsGuidesByCountry
      .map((item) => ({
        label: getCountryCodeName(item.id) || item.id,
        value: item.count,
      }))
      .filter((item) => Boolean(item.label)),
  );

  const {
    adminChartsTraineesByCountry = [],
    isLoadingAdminChartsTraineesByCountry,
  } = useAdminChartsTraineesByCountry({
    params: {
      filterModel: userModel.filterModel,
    },
    options: {
      onError: (error) => {
        setError({
          message: error.message,
          title: 'Failed to get Guides by Country Chart',
        });
      },
    },
  });

  const parsedAdminChartsTraineesByCountry = getOthers(
    adminChartsTraineesByCountry
      .map((item) => ({
        label: getCountryCodeName(item.id) || item.id,
        value: item.count,
      }))
      .filter((item) => Boolean(item.label)),
  );

  const {
    adminKPIsOrganization,
    isLoadingAdminKPIsOrganization,
    isValidatingAdminKPIsOrganization,
    revalidateAdminKPIsOrganization,
  } = useAdminKPIsOrganization({
    params: {
      filterModel: kpiModel.filterModel,
    },
  });

  const {
    adminChartsGoalsCumulative,
    isValidatingAdminChartsGoalsCumulative,
    isLoadingAdminChartsGoalsCumulative,
    revalidateAdminChartsGoalsCumulative,
  } = useAdminChartsGoalsCumulative({
    params: { filterModel: goalsCumulativeModel.filterModel },
  });

  const {
    adminChartsSkillsCumulative,
    isValidatingAdminChartsSkillsCumulative,
    isLoadingAdminChartsSkillsCumulative,
    revalidateAdminChartsSkillsCumulative,
  } = useAdminChartsSkillsCumulative({
    params: { filterModel: skillsCumulativeModel.filterModel },
  });

  const isGraphsLoading =
    isLoadingAdminChartsGoalsCumulative ||
    isLoadingAdminChartsSkillsCumulative ||
    isLoadingAdminOvertimeCharts ||
    adminChartsGoalsCumulative === undefined ||
    adminChartsSkillsCumulative === undefined;

  const isLoading =
    isLoadingAuth ||
    isLoadingOrganization ||
    !isAuthorized ||
    !isAuthenticated ||
    isLoadingAdminChartsGoals ||
    isLoadingAdminChartsUsers ||
    isLoadingAdminChartsOverviewTraineeProfiles ||
    isLoadingAdminKPIsOrganization ||
    isLoadingAdminChartsOverviewGuideProfiles ||
    isGraphsLoading;

  const isGraphsValidating =
    isValidatingAdminChartsGoalsCumulative ||
    isValidatingAdminChartsSkillsCumulative ||
    isValidatingAdminOvertimeCharts;

  const isValidating =
    isValidatingOrganization ||
    isValidatingAdminChartsGoals ||
    isValidatingAdminChartsOverviewGuideProfiles ||
    isValidatingAdminChartsOverviewTraineeProfiles ||
    isValidatingAdminChartsUsers ||
    isValidatingAdminKPIsOrganization ||
    isGraphsValidating;

  function getUpdatedAt(): string | undefined {
    try {
      const now = new Date();
      const localeData = Intl.DateTimeFormat().resolvedOptions();

      const updatedAtString = `Updated today at ${now.toLocaleTimeString(
        localeData.locale,
        {
          hour12: true,
        },
      )} (${getTimezoneAbbreviation(localeData.timeZone)})`;

      return updatedAtString;
    } catch (err: unknown) {
      return undefined;
    }
  }

  function refresh() {
    revalidateAdminChartsGoals();
    revalidateAdminChartsUsers();
    revalidateAdminKPIsOrganization();
    revalidateAdminChartsOverviewGuideProfiles();
    revalidateAdminChartsOverviewTraineeProfiles();
    revalidateAdminOvertimeCharts();
    revalidateAdminChartsGoalsCumulative();
    revalidateAdminChartsSkillsCumulative();
    getUpdatedAt();
  }

  const adminKPIOrganization = adminKPIsOrganization?.at(0);
  const memberCount = adminKPIOrganization?.memberCount;
  const guideMemberships = adminKPIOrganization?.guideMemberships;
  const traineeMemberships = adminKPIOrganization?.traineeMemberships;
  const activeRelationshipCount = adminKPIOrganization?.activeRelationshipCount;
  const concludedRelationshipCount =
    adminKPIOrganization?.concludedRelationshipCount;
  const goalsCreatedCount = adminKPIOrganization?.goalsCreatedCount;
  const navigate = useNavigate();

  const today = new Date().toLocaleDateString();

  // Calculator fields
  const sanityOrganizationCostSaving = sanityOrganization?.metrics?.cost_saving;
  const calculatorCurrency = sanityOrganizationCostSaving?.currency ?? '£';
  const costVsCoachingExplainer =
    baseLanguage?.metrics?.cost_training_explainer;
  const costVsCoachingHeader = baseLanguage?.metrics?.cost_coaching_heading;
  const costVsTrainingSavedExplainer =
    baseLanguage?.metrics?.cost_training_explainer;
  const costVsTrainingSavedHeading =
    baseLanguage?.metrics?.cost_training_heading;
  const costVsLmsExplainer = baseLanguage?.metrics?.cost_lms_explainer;
  const costVsLmsHeading = baseLanguage?.metrics?.cost_lms_heading;
  const sessionCount =
    sanityOrganizationCostSaving?.number_of_sessions ??
    adminKPIOrganization?.sessionCount ??
    1;
  const userCount =
    sanityOrganizationCostSaving?.number_of_users ?? memberCount ?? 1;
  const costSavingDefaults = settings?.metrics;
  const coachRate =
    sanityOrganizationCostSaving?.coach_rate ??
    costSavingDefaults?.coach_rate ??
    1;
  const costToTrain =
    sanityOrganizationCostSaving?.cost_to_train ??
    costSavingDefaults?.cost_to_train ??
    1;
  const lmsCoast =
    sanityOrganizationCostSaving?.lms_cost ?? costSavingDefaults?.lms_cost ?? 1;
  const guiderCost = sanityOrganizationCostSaving?.cost_of_guider ?? 1;
  const getRoi = (count: number, rate: number, guiderCost: number) =>
    Math.ceil((count * rate) / guiderCost).toLocaleString();

  const sanityOrganizationFields = sanityOrganization?.profile_fields ?? [];
  const sanityOrganizationPersonalDetails =
    sanityOrganization?.personal_details;
  const isSanityOrganizationCountryEnabled =
    sanityOrganization?.personal_details.country.enabled ?? false;
  const sanityGenderProfileField =
    sanityOrganizationPersonalDetails?.gender?.enabled;
  const sanityGenderOptions = baseLanguage?.profile_fields?.gender?.options;
  const isSanityOrganizationTownOrCityEnabled =
    sanityOrganizationPersonalDetails?.town_or_city.enabled ?? false;
  const isSanityOrganizationJobTitleEnabled =
    sanityOrganizationPersonalDetails?.job_title.enabled ?? false;
  const isSanityOrganizationPronounsEnabled =
    sanityOrganizationPersonalDetails?.pronouns.enabled ?? false;
  const isSanityOrganizationDateOfBirthEnabled =
    sanityOrganization?.personal_details.date_of_birth.enabled ?? false;
  const isSanityOrganizationLinkedInUrlEnabled =
    sanityOrganization?.personal_details.linkedin_url.enabled ?? false;
  const isSanityOrganizationPhoneNumberEnabled =
    sanityOrganization?.personal_details.phone_number.enabled ?? false;

  const userOrganizationField = getSanityProfileFieldColumns<IChartsUser>({
    fields: sanityOrganizationFields,
    customFieldId: (id) => `organizationFields.${id}`,
    getColumnOptions: (id) => ({
      minWidth: 130,
      hideable: true,
      valueGetter: ({ row }) => {
        const fieldSlug: keyof IChartsUser['organizationFields'] = id;
        const organizationFields = row.organizationFields;

        return organizationFields[fieldSlug];
      },
    }),
  });

  const sanityOrganizationLimitedLicenses =
    sanityOrganization?.basic_info.limited_licenses;
  const sanityOrganizationNumberOfLicenses =
    adminKPIOrganization?.limitedLicenses;

  countriesRegisterLocale(require(`i18n-iso-countries/langs/en.json`));

  const getGenderColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'gender',
        headerName: 'Gender',
        valueFormatter: getGenderLabel(sanityGenderOptions),
      });
    }

    return column;
  };

  const getJobTitleColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'jobRole',
        headerName: 'Job Role',
      });
    }

    return column;
  };

  const getDateOfBirthColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
      });
    }

    return column;
  };

  const getPronounsColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'pronouns',
        headerName: 'Pronouns',
      });
    }

    return column;
  };

  const getTownOrCityColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'townOrCity',
        headerName: 'Town or City',
      });
    }

    return column;
  };

  const getLinkedInUrlColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'linkedInUrl',
        headerName: 'Linked In Url',
      });
    }

    return column;
  };

  const getPhoneNumberColumn = (field: boolean | undefined) => {
    const column: GridColDef[] = [];

    if (field !== false || isRootDomain) {
      column.push({
        field: 'phoneNumber',
        headerName: 'Phone Number',
      });
    }

    return column;
  };

  const userColumnsParsed: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      minWidth: 100,
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    ...getOrganizationColumn(organizationsDetails, isRootDomain),
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 250,
      flex: 0.5,
      renderCell: (params) => (
        <Button
          variant="text"
          size="small"
          sx={{
            maxWidth: '100%',
          }}
          color="primary"
          onClick={() => {
            navigate(`/users/${params.id}`);
          }}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: 'givenName',
      headerName: 'First name',
      minWidth: 100,
    },
    { field: 'familyName', headerName: 'Last name', minWidth: 100 },
    {
      ...getUserRoleColumn(guideLabel, traineeLabel),
    },
    {
      field: 'programsWhereIsGuide',
      headerName: `${guideLabel} profiles`,
      minWidth: 200,
      valueFormatter: ({ value }) => getProgramsLabelsBySlugs(value),
    },
    {
      field: 'programsWhereIsTrainee',
      headerName: `${traineeLabel} profiles`,
      minWidth: 200,
      valueFormatter: ({ value }) => getProgramsLabelsBySlugs(value),
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
      type: 'number',
    },
    {
      field: 'concludedRelationships',
      headerName: concludeRelationshipKpiLabel,
      type: 'number',
    },

    {
      field: 'status',
      headerName: 'Status',
      valueFormatter: ({ value }) => parsedProfileStatus(value),
    },
    { field: 'sessionsHeld', headerName: 'Sessions held', type: 'number' },
    {
      field: 'totalSessionMinutes',
      headerName: 'Total attendance',
      type: 'number',
      valueFormatter: ({ value }) => {
        return formatDuration({ minutes: value }, { zero: true });
      },
      width: 110,
    },
    ...getJobTitleColumn(isSanityOrganizationJobTitleEnabled),
    ...getGenderColumn(sanityGenderProfileField),
    ...getPronounsColumn(isSanityOrganizationPronounsEnabled),
    ...getTownOrCityColumn(isSanityOrganizationTownOrCityEnabled),
    ...getDateOfBirthColumn(isSanityOrganizationDateOfBirthEnabled),
    ...getLinkedInUrlColumn(isSanityOrganizationLinkedInUrlEnabled),
    ...getPhoneNumberColumn(isSanityOrganizationPhoneNumberEnabled),
    ...getCountryColumn({
      field: 'country',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    { field: 'goals', headerName: 'Goals', type: 'number' },
    ...userOrganizationField,
  ];

  function getProgramsLabelsBySlugs(
    programSlug: string | number | string[] | Date | undefined,
  ): string {
    const programsSlugs = Array.isArray(programSlug)
      ? programSlug.map(String)
      : (String(programSlug).split(';') || []).map((slug) => slug.trim());

    const programsLabels = programsSlugs
      .map(
        (slug) =>
          adminPrograms?.find((program) => program.programSlug === slug)
            ?.programName,
      )
      .filter((label) => label !== undefined);

    return programsLabels.length > 0 ? programsLabels.join(', ') : 'n/a';
  }

  const guideMembershipsColumns: GridColDef<IChartsOverviewGuideProfile>[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    { field: 'firstName', headerName: 'First name', minWidth: 100 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.3 },
    ...getCountryColumn({
      field: 'country',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'programsWhereIsGuide',
      headerName: `${guideLabel} profiles`,
      valueFormatter: ({ value }) => getProgramsLabelsBySlugs(value),
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
      type: 'number',
    },
    {
      field: 'concludedRelationships',
      headerName: concludeRelationshipKpiLabel,
      type: 'number',
    },
    { field: 'sessionsHeld', headerName: 'Sessions held', type: 'number' },
  ];

  const traineeMembershipsColumns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Created on',
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value),
      valueFormatter: ({ value }) =>
        value && format(new Date(value), 'dd/MM/yyyy'),
      minWidth: 100,
      filterOperators: [getCustomGridDateFilterOperator({})],
    },
    { field: 'firstName', headerName: 'First name', minWidth: 100 },
    { field: 'lastName', headerName: 'Last name', minWidth: 100 },
    { field: 'email', headerName: 'Email', minWidth: 200, flex: 0.3 },
    ...getCountryColumn({
      field: 'country',
      enabled: isSanityOrganizationCountryEnabled,
      countryOptions: countryOptions,
    }),
    {
      field: 'programsWhereIsTrainee',
      headerName: `${traineeLabel} Profiles`,
      valueFormatter: ({ value }) => getProgramsLabelsBySlugs(value),
      minWidth: 200,
      flex: 0.5,
    },
    {
      field: 'activeRelationships',
      headerName: 'Active relationships',
      type: 'number',
    },
    {
      field: 'concludedRelationships',
      headerName: concludeRelationshipKpiLabel,
      type: 'number',
    },
    { field: 'sessionsHeld', headerName: 'Sessions held', type: 'number' },
  ];

  // Goals table

  const goalsColumns = getGoalsColumns({
    traineeLabel: traineeLabel,
    guideLabel: guideLabel,
    isSanityOrganizationCountryEnabled: isSanityOrganizationCountryEnabled,
    countryOptions: countryOptions,
  });

  const keyStats: IKeyStatCard[] = [
    {
      label: costVsCoachingHeader,
      toolTipMessage: costVsCoachingExplainer,
      value: `${calculatorCurrency}${(
        sessionCount * coachRate -
        guiderCost
      ).toLocaleString()}`,
      key: 'costVsCoaching',
      description: `ROI ${getRoi(sessionCount, coachRate, guiderCost)}`,
      isVisible: Boolean(sanityOrganizationCostSaving?.cost_vs_coaching),
      loading:
        isLoadingBaseLanguage ||
        isLoadingSettings ||
        isLoadingOrganization ||
        isLoadingAdminKPIsOrganization,
    },
    {
      label: costVsTrainingSavedHeading,
      toolTipMessage: costVsTrainingSavedExplainer,
      value: `${calculatorCurrency}${(
        userCount * costToTrain -
        guiderCost
      ).toLocaleString()}`,
      key: 'costVsCoaching',
      description: `ROI ${getRoi(userCount, costToTrain, guiderCost)}`,
      isVisible: Boolean(sanityOrganizationCostSaving?.cost_vs_training),
      loading:
        isLoadingBaseLanguage ||
        isLoadingSettings ||
        isLoadingOrganization ||
        isLoadingAdminKPIsOrganization,
    },
    {
      label: costVsLmsHeading,
      toolTipMessage: costVsLmsExplainer,
      value: `${calculatorCurrency}${(
        userCount * lmsCoast -
        guiderCost
      ).toLocaleString()}`,
      key: 'costVsCoaching',
      description: `ROI ${getRoi(userCount, lmsCoast, guiderCost)}`,
      isVisible: Boolean(sanityOrganizationCostSaving?.cost_vs_lms),
      loading:
        isLoadingBaseLanguage ||
        isLoadingSettings ||
        isLoadingOrganization ||
        isLoadingAdminKPIsOrganization,
    },
    {
      label: 'Users',
      toolTipMessage:
        'The total number of individuals enrolled in the organization.',
      value: memberCount,
      key: 'users',
      description: 'users',
      ref: 'usersTable',
      isVisible: true,
    },
    {
      label: 'Active relationships',
      toolTipMessage:
        'The total number of active relationships across all programs in the organization.',
      value: activeRelationshipCount,
      key: 'relationships',
      percentageNumber:
        activeRelationshipCount && adminKPIOrganization?.relationshipCount
          ? activeRelationshipCount / adminKPIOrganization?.relationshipCount
          : 0,
      description:
        activeRelationshipCount && adminKPIOrganization?.relationshipCount
          ? `${Math.round(
              (activeRelationshipCount /
                adminKPIOrganization?.relationshipCount) *
                100,
            )}% of total relationships`
          : '',
      isVisible: true,
    },
    {
      label: concludeRelationshipKpiLabel,
      value: concludedRelationshipCount,
      key: 'concludedRelationships',
      toolTipMessage:
        'The total number of successfully completed relationships across all programs in the organization.',
      percentageNumber:
        concludedRelationshipCount && adminKPIOrganization?.relationshipCount
          ? concludedRelationshipCount / adminKPIOrganization?.relationshipCount
          : 0,
      description:
        concludedRelationshipCount && adminKPIOrganization?.relationshipCount
          ? `${Math.round(
              (concludedRelationshipCount /
                adminKPIOrganization?.relationshipCount) *
                100,
            )}% of total relationships`
          : '',
      isVisible: true,
    },
    {
      label: `${guideLabel} memberships`,
      toolTipMessage: `The total number of ${guideLabel} memberships across all programs in the organization.`,
      value: guideMemberships,
      key: 'guideMemberships',
      percentageNumber:
        (guideMemberships ?? 0) /
        ((guideMemberships ?? 0) + (traineeMemberships ?? 0)),
      description: `${Math.round(
        ((guideMemberships ?? 0) /
          ((guideMemberships ?? 0) + (traineeMemberships ?? 0))) *
          100,
      )}% of total program memberships`,
      ref: 'guideTable',
      isVisible: true,
    },
    {
      label: `${traineeLabel} memberships`,
      toolTipMessage: `The total number of ${traineeLabel} memberships across all programs in the organization.`,
      value: traineeMemberships,
      key: 'traineeMemberships',
      percentageNumber:
        (traineeMemberships ?? 0) /
        ((guideMemberships ?? 0) + (traineeMemberships ?? 0)),
      description: `${Math.round(
        ((traineeMemberships ?? 0) /
          ((traineeMemberships ?? 0) + (guideMemberships ?? 0))) *
          100,
      )}% of total program memberships`,
      ref: 'traineeTable',
      isVisible: true,
    },
    {
      label: 'Sessions',
      toolTipMessage:
        'The total number of sessions booked/logged across all relationships in the organization.',
      value: adminKPIOrganization?.sessionCount,
      key: 'sessions',
      description: 'sessions',
      isVisible: true,
    },
    {
      label: 'Relationships created',
      toolTipMessage:
        'The total number of relationships created across all programs in the organization.',
      value: adminKPIOrganization?.relationshipCount,
      key: 'relationships',
      description: 'relationships',
      isVisible: true,
    },
    {
      label: 'Goals achieved',
      toolTipMessage:
        'The total number of goals achieved in this organization as reported by the goal creator.',
      value: adminKPIOrganization?.goalsAchievedCount,
      key: 'goalsAchieved',
      description: 'goalsAchieved',
      isVisible: true,
    },
    {
      label: 'Goals created',
      toolTipMessage: 'The total number of goals created in this organization.',
      value: goalsCreatedCount,
      key: 'goalsCreated',
      description: 'goalsCreated',
      ref: 'goalsTable',
      isVisible: true,
    },
    {
      isVisible: isRootDomain && sanityOrganizationLimitedLicenses === true,
      label: 'Licenses',
      toolTipMessage: 'The number of licenses allocated to this organization',
      value: sanityOrganizationNumberOfLicenses,
      percentageNumber:
        memberCount && sanityOrganizationNumberOfLicenses
          ? memberCount / sanityOrganizationNumberOfLicenses
          : 0,
      description: `${
        memberCount && sanityOrganizationNumberOfLicenses
          ? calculatePercentage(memberCount, sanityOrganizationNumberOfLicenses)
          : 0
      }% of licenses used`,
      key: 'licenses',
    },
  ].filter((stat) => stat.isVisible);

  function calculatePercentage(part: number, total: number) {
    let percentage = (part / total) * 100;

    return percentage.toFixed(2);
  }

  const relationshipsPieChartData = [
    {
      label: 'Active',
      value: activeRelationshipCount ?? 0,
      color: palette.success.main,
    },
    {
      label: concludeRelationshipKpiLabel,
      value: concludedRelationshipCount ?? 0,
      color: '#C6D9D5',
    },
  ];

  const membershipsPieChartData = [
    {
      label: `${guideLabelPluralized}`,
      value: guideMemberships ?? 0,
      color: palette.warning.main,
    },
    {
      label: `${traineeLabelPluralized}`,
      value: traineeMemberships ?? 0,
      color: palette.warning.light,
    },
  ];

  const relationshipsTotal =
    relationshipsPieChartData
      .map((item) => item.value)
      .reduce((a = 0, b = 0) => a + b, 0) ?? 0;

  const getRelationshipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / relationshipsTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const membershipsTotal =
    membershipsPieChartData
      .map((item) => item.value)
      .reduce((a = 0, b = 0) => a + b, 0) ?? 0;

  const getMembershipsArcLabel = (params: DefaultizedPieValueType) => {
    const percent = params.value / membershipsTotal;
    return `${(percent * 100).toFixed(0)}%`;
  };

  const handleCardClick = (cardName?: string) => {
    if (!cardName) {
      return;
    }
    if (tableRefs[cardName].current) {
      tableRefs[cardName]!.current!.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <PageView
      title={'Overview'}
      subtitles={[
        {
          text: 'Think of this overview as the movie trailer for your data saga - all the highlights without the spoilers.',
          variant: 'subtitle2',
          component: 'p',
          sx: { textWrap: 'pretty' },
        },
      ]}
      headerSx={{
        alignItems: 'flex-start',
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? '24px' : 0,
        textWrap: 'nowrap',
      }}
      headerElement={
        <Stack
          direction={'column'}
          spacing={0}
          width={'100%'}
          alignItems={'flex-end'}
          flexWrap={isMobile ? 'wrap' : undefined}
          paddingRight={1}
        >
          <Button
            variant="textWithIcon"
            startIcon={<Refresh />}
            label="Refresh data"
            onClick={refresh}
            loading={isLoading || isValidating}
          />
          <Text
            text={getUpdatedAt()}
            variant="subtitle2"
            color={palette.paper.text.disabled}
            sx={{ textWrap: 'nowrap' }}
          />
        </Stack>
      }
      sx={{
        backgroundColor: palette.paper.background.primary,
      }}
    >
      <Stack direction="row" width="100%" py={3} px={1}>
        <MetricsOverviewFilterContainer
          countryOptions={filteredCountries}
          countryEnabled={isSanityOrganizationCountryEnabled}
        />
      </Stack>
      <Stack direction="column" width="100%" gap={5}>
        <MuiGrid
          container
          direction="row"
          alignItems="stretch"
          spacing={1}
          rowGap={2}
        >
          {keyStats.map((stat) => (
            <MuiGrid
              key={stat.key}
              item
              xs={12}
              sm={6}
              md={4}
              xl={4}
              paddingRight={2}
            >
              <KeyStatCard
                key={`${stat.key}`}
                label={stat.label}
                toolTipMessage={stat.toolTipMessage}
                value={stat.value}
                description={stat.description}
                percentageNumber={stat.percentageNumber}
                cardRef={stat.ref ? () => handleCardClick(stat.ref) : undefined}
                loading={stat.loading}
              />
            </MuiGrid>
          ))}
        </MuiGrid>
        <MuiGrid container rowSpacing={3} direction="row">
          <StackedChartContainer
            isLoading={isGraphsLoading && isGraphsValidating}
            labels={{
              guidePluralized: guideLabelPluralized,
              traineePluralized: traineeLabelPluralized,
            }}
            organizationSlug={organizationSlug}
            filterModel={overtimeModel.filterModel}
          />
        </MuiGrid>
        <StackedBarChartsContainer
          isLoading={
            isGraphsLoading ||
            adminChartsGoalsCumulative === undefined ||
            adminChartsSkillsCumulative === undefined
          }
          organizationSlug={organizationSlug}
          traineeLabelPluralized={traineeLabelPluralized}
        />
        <MuiGrid
          container
          rowSpacing={2}
          gap={isMobile ? 2 : 0}
          direction={'row'}
          display={'flex'}
        >
          <PieChartCard
            title="Relationships"
            data={relationshipsPieChartData}
            isLoading={
              isLoadingAdminKPIsOrganization ||
              adminKPIOrganization === undefined
            }
            arcLabel={getRelationshipsArcLabel}
            fileName={`${organizationSlug}-relationships-${today}`}
            sxProps={{ mr: isMobile ? 0 : 3 }}
          />
          <PieChartCard
            title="Memberships"
            data={membershipsPieChartData}
            isLoading={
              isLoadingAdminKPIsOrganization ||
              adminKPIOrganization === undefined
            }
            arcLabel={getMembershipsArcLabel}
            fileName={`${organizationSlug}-memberships-${today}`}
          />
        </MuiGrid>

        {(isSanityOrganizationCountryEnabled ||
          adminChartsUsersByCustomFields.length !== 0) && (
          <MuiGrid
            container
            rowSpacing={2}
            gap={isMobile ? 2 : 0}
            direction={'row'}
            display={'flex'}
          >
            <PieChartCard
              title="Users by country"
              data={parsedAdminChartsUsersByCountry}
              isLoading={isLoadingAdminChartsUsersByCountry}
              fileName={`${organizationSlug}-users-by-country-${today}`}
              sxProps={{ mr: isMobile ? 0 : 3 }}
            />
            {adminChartsUsersByCustomFields.length !== 0 && (
              <PieChartCard
                title="Users by division"
                data={parsedAdminChartsUsersByCustomFields || []}
                isLoading={isLoadingAdminChartsUsersByCustomFields}
                fileName={`${organizationSlug}-users-by-division-${today}`}
              />
            )}
          </MuiGrid>
        )}

        {isSanityOrganizationCountryEnabled && (
          <MuiGrid
            container
            rowSpacing={2}
            gap={isMobile ? 2 : 0}
            direction={'row'}
            display={'flex'}
          >
            <PieChartCard
              title={`${guideLabelPluralized} by country`}
              data={parsedAdminChartsGuidesByCountry}
              isLoading={isLoadingAdminChartsGuidesByCountry}
              fileName={`${organizationSlug}-guides-by-country-${today}`}
              sxProps={{ mr: isMobile ? 0 : 3 }}
            />
            <PieChartCard
              title={`${traineeLabelPluralized} by country`}
              data={parsedAdminChartsTraineesByCountry}
              isLoading={isLoadingAdminChartsTraineesByCountry}
              fileName={`${organizationSlug}-trainees-by-country-${today}`}
            />
          </MuiGrid>
        )}
        <div ref={tableRefs['usersTable']}>
          <MongoMetricsContainer
            reportName="users"
            filename={`${organizationSlug ?? 'guider'}-all-users-${today}`}
            metricsTableProps={{
              ...userModel,
              heading: 'All users',
              rows: adminChartsUsers,
              columns: userColumnsParsed,
              loading: isLoadingAdminChartsUsers,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateUserModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateUserModel({ sortModel });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateUserModel({ paginationModel: model });
              },
            }}
          />
        </div>
        <div ref={tableRefs['guideTable']}>
          <MongoMetricsContainer
            reportName="overview-guide-profiles"
            filename={`${
              organizationSlug ?? 'guider'
            }-guide-membership-${today}`}
            metricsTableProps={{
              ...guideMembershipsModel,
              heading: `${guideLabel} memberships`,
              rows: adminChartsOverviewGuideProfiles,
              columns: guideMembershipsColumns,
              loading: isLoadingAdminChartsOverviewGuideProfiles,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateGuideMembershipsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateGuideMembershipsModel({
                  sortModel,
                });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateGuideMembershipsModel({ paginationModel: model });
              },
            }}
          />
        </div>
        <div ref={tableRefs['traineeTable']}>
          <MongoMetricsContainer
            reportName="overview-trainee-profiles"
            filename={`${
              organizationSlug ?? 'guider'
            }-trainee-membership-${today}`}
            metricsTableProps={{
              heading: `${traineeLabel} memberships`,
              rows: adminChartsOverviewTraineeProfiles,
              columns: traineeMembershipsColumns,
              loading: isLoadingAdminChartsOverviewTraineeProfiles,
              filterMode: 'server',
              ...traineeMembershipsModel,
              onFilterModelChange: (filterModel) => {
                updateTraineeMembershipsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateTraineeMembershipsModel({
                  sortModel,
                });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateTraineeMembershipsModel({ paginationModel: model });
              },
            }}
          />
        </div>
        <div ref={tableRefs['goalsTable']}>
          <MongoMetricsContainer
            reportPath="goals"
            filename={`${organizationSlug ?? 'guider'}-goals-${today}`}
            metricsTableProps={{
              ...goalsModel,
              heading: `Goals`,
              rows: adminChartsGoals,
              columns: goalsColumns,
              loading: isLoadingAdminChartsGoals,
              filterMode: 'server',
              onFilterModelChange: (filterModel) => {
                updateGoalsModel({
                  filterModel: filterModel as IFilterModel,
                });
              },
              sortingMode: 'server',
              onSortModelChange: (sortModel) => {
                updateGoalsModel({
                  sortModel,
                });
              },
              pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
              paginationMode: 'server',
              onPaginationModelChange: (model) => {
                updateGoalsModel({ paginationModel: model });
              },
            }}
          />
        </div>
      </Stack>
    </PageView>
  );
}

const parsedProfileStatus = (status?: string) => {
  switch (status) {
    case 'matched':
      return 'Matched';
    case 'joinedProgram':
      return 'Program profile created';
    case 'completedProfile':
      return 'Signed up';
    case 'signedUp':
      return 'Signed up';
    default:
      return status;
  }
};

const parsedProfileRoles = (
  status: string,
  guideLabel: string = 'Guide',
  traineeLabel: string = 'Trainee',
) => {
  switch (status) {
    case 'guide':
      return guideLabel;
    case 'trainee':
      return traineeLabel;
    case 'both':
      return 'Both';
    case 'noMembership':
      return 'No Role';
    default:
      return status;
  }
};

const getUserRoleOptions = (
  guideLabel?: string,
  traineeLabel?: string,
): IMultiSelectChipFilterOption[] => [
  {
    id: 'guide',
    label: parsedProfileRoles('guide', guideLabel, traineeLabel),
    bgColor: '#00307A',
  },
  {
    id: 'trainee',
    label: parsedProfileRoles('trainee', guideLabel, traineeLabel),
    bgColor: '#008CF9',
  },
  {
    id: 'both',
    label: parsedProfileRoles('both', guideLabel, traineeLabel),
    bgColor: '#00BB92',
  },
  {
    id: 'noMembership',
    label: parsedProfileRoles('noMembership', guideLabel, traineeLabel),
  },
];

export const getUserRoleColumn = (
  guideLabel?: string,
  traineeLabel?: string,
): GridColDef => {
  const options = getUserRoleOptions(guideLabel, traineeLabel);
  return {
    field: 'role',
    headerName: 'Role',
    minWidth: 150,
    renderCell: (params) => {
      const getChipColor = () => {
        const color = options.find((option) => option.id === params.value)
          ?.bgColor;
        if (color) return color;
        return undefined;
      };
      const color = getChipColor();
      return (
        <Chip
          label={params.formattedValue}
          size="small"
          bgColor={color}
          sx={{
            display: params.value === 'noMembership' ? 'none' : '',
          }}
        />
      );
    },
    valueFormatter: ({ value }) =>
      parsedProfileRoles(value, guideLabel, traineeLabel),
    filterOperators: [
      getCustomGridAutocompleteFilterOperator({
        componentProps: {
          options: options,
        },
      }),
    ],
  };
};
